import { useStaticQuery, graphql } from 'gatsby'

export const useResponsibleTravel = (slug) => {
  const { allContentfulCharitablePartners } = useStaticQuery(graphql`
    query ResponsibleTravelQuery {
      allContentfulCharitablePartners {
        edges {
          node {
            __typename
            image: highlightImage {
              gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 90)
            }
            description {
              description
            }
            name
            slug
            content {
              json: raw
            }
          }
        }
      }
    }
  `)

  const responsibleTravel = allContentfulCharitablePartners.edges.filter(({ node }) => node.slug !== slug)

  return responsibleTravel.map(({ node }) => {
    return {
      ...node,
    }
  })
}
