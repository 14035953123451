import React from 'react'
import { Row, Column, Card, Text, Button } from '@roar/components'
import { Box, Flex } from 'rebass/styled-components'
import { mapperWithFunction } from '../common/utils'
import { SectionHeading } from '@roar/components'
import { Link } from 'gatsby'
import { useResponsibleTravel } from '../data/hooks/responsibleTravel'
import { formatImage } from './ImageWrapper'

const RELATED_ITEMS_MAP = {
  date: ['date'],
  image: (value) => formatImage(value, true),
  headline: ['name'],
  description: ['description', 'description'],
  slug: ['slug'],
  parent: ['parent'],
}

const SectionWrapper = (props) => {
  const { currentSlug, description, showFilter = false, parent } = props
  const sectionData = useResponsibleTravel(currentSlug)

  return (
    <Box bg="brandBeigeLight" pb={[4, 15]} px={[2, 14]}>
      <SectionHeading
        description={description}
        renderAction={
          showFilter && (
            <Flex justifyContent="flex-end" alignItems="flex-start">
              <Button variant="text" colorScheme="red" hasUnderline={true}>
                All
              </Button>
            </Flex>
          )
        }
      />

      <Box>
        <Row justifyContent="flex-start">
          {sectionData &&
            sectionData.map((b) => {
              const {
                image = null,
                headline = '',
                description = '',
                slug = '',
              } = mapperWithFunction(b, RELATED_ITEMS_MAP)

              return (
                <Column width={[1, 1, 6 / 12, 4 / 12]} mb={[2, 4]} flex="0 1 auto">
                  <Link to={`/${parent}/${slug}`}>
                    <Card
                      alignment="left"
                      variant="standard"
                      image={image}
                      meta={null}
                      headline={headline}
                      description={<Text>{description}</Text>}
                    />
                  </Link>
                </Column>
              )
            })}
        </Row>
      </Box>
    </Box>
  )
}

export default SectionWrapper
